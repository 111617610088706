import React, { useState, useRef, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useOnClickOutside } from './hooks';
import { GlobalStyles } from './global';
import { theme } from './theme';
import { Burger, Menu } from './components';
import { LogoContext } from './Helper/Context';

import FocusLock from 'react-focus-lock';
import Finance from './pages/Finance/Finance';
import Ecommerce from './pages/Ecommerce/Ecommerce';
import Flows from './pages/Flows/Flows';
import Okta from './pages/Okta/Okta';
import Landing from './pages/Landing/Landing';
import Editor from './pages/Editor/Editor';

import Profile from './pages/Profile/Profile';
import General from './views/Main/General';
import Account from './views/Second/Account';
import Token from './views/Token/Token';

import Init from './pages/Init/Init';
import Tenant from './pages/Tenant/Tenant';

import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';

import CookieManager from './helpers/CookieManager';
import { AiOutlineConsoleSql } from 'react-icons/ai';

function App() {
  const [open, setOpen] = useState(false);
  let [logoContext, setLogoContext] = useState(null);
  let [text, setText] = useState(null);
  const node = useRef();
  const menuId = 'main-menu';
  // console.log("LOCATION: ", location);

  let cookieManager = new CookieManager();

  useOnClickOutside(node, () => setOpen(false));
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  const handleMouseMove = (e) => {
    if (e.clientX > 200) {
      setOpen(false);
    }
  };
  return (
    <Router>
      <LogoContext.Provider
        value={{ logoContext, setLogoContext, text, setText }}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {cookieManager.hasAllCookies() && (
            <div ref={node} id="menu-div-for-routes">
              <FocusLock disabled={!open}>
                <Burger open={open} setOpen={setOpen} aria-controls={menuId} id="styled-burger"/>
                <Menu open={open} setOpen={setOpen} id={menuId} />
              </FocusLock>
            </div>
          )}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/editor" element={<Editor />} />
            <Route path="/flows" element={<Flows />} />
            <Route path="/okta" element={<Okta />} />
            <Route path="/profile" element={<Profile />}>
              <Route path="general" element={<General />} />
              <Route path="account" element={<Account />} />
              <Route path="token" element={<Token />} />
            </Route>
            {/* <Route path="/init" element={<Init />} /> */}
            <Route path="/tenant" element={<Tenant />} />
          </Routes>
        </ThemeProvider>
      </LogoContext.Provider>
    </Router>
  );
}

export default App;
