import React, { useState, useRef, useEffect } from 'react';
import './main.css';
import GeneralForm from './GeneralForm';
import { useAuth0 } from '@auth0/auth0-react';
import auth0 from 'auth0-js';

const General = () => {
    const { isAuthenticated, user, logout } = useAuth0();

    return <div id="main-profile-container">
        
        <div id="general-form"> 
            <GeneralForm /> 
        </div>
        <div id="profile-photo"> 
            <div id="profile-image"> 
                <div id="profile-image-div">
                    <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" />
                </div>
                {/* <p id="profile-image-name"> Nithin Kumar Moorthy </p>
                <p id="profile-member-info"> Member since July 17th, 2019 </p> */}
                {/* <div>
                    <p id="profile-image-name" class="profile-info"> Nithin Kumar Moorthy </p>
                </div>
                <div>
                    <p id="profile-image-member-since" class="profile-info"> Member since 07/21 </p>
                </div> */}
                {/* <div>
                    <p id="profile-image-member-type" class="profile-info"> Premium Member </p>
                </div> */}
                <div>
                    <button id="edit-image"> Edit </button>
                </div>


            </div>
           
        </div> 
    </div>
}

export default General;