class CookieManager {
  constructor() {
    this.cookie_consts = [
      { domain: 'domain' },
      { MDT_finance: 'MDT_finance' },
      { MDT_ecommerce: 'MDT_ecommerce' },
      { MDT_custom_editor: 'MDT_custom_editor' },
      { app_domain: 'app_domain' },
      { tenant: 'tenant' },
      { base_app_domain: 'base_app_domain' },
    ];
    this.logged_in = 'logged_in';
    // this.domain = 'domain';
    // this.MDT_finance = 'MDT_finance';
    // this.MDT_ecommerce = 'MDT_ecommerce';
    // this.MDT_custom_editor = 'MDT_custom_editor';
    // this.app_domain = 'app_domain';
    // this.tenant = 'tenant';
  }

  getCookies = () => {
    let cookieObj = {};
    for (let cookie_const of this.cookie_consts) {
      let cookie = Object.entries(cookie_const)[0];
      let key = cookie[0];
      cookieObj[key] = this.getCookie(key);
    }
    // console.log(cookieObj);
    return cookieObj;
    // return {
    //   domain: this.getCookie(this.domain),
    //   MDT_finance: this.getCookie(this.MDT_finance),
    //   MDT_ecommerce: this.getCookie(this.MDT_ecommerce),
    //   MDT_custom_editor: this.getCookie(this.MDT_custom_editor),
    //   app_domain: this.getCookie(this.app_domain),
    //   tenant: this.getCookie(this.tenant),
    // };
  };

  hasAllCookies = () => {
    for (let cookie_const of this.cookie_consts) {
      let cookie = Object.entries(cookie_const)[0];
      let key = cookie[0];
      if (!this.getCookie(key)) {
        return false;
      }
      return true;
    }
    return true;
    // return (
    //   this.getCookie(this.domain) &&
    //   this.getCookie(this.MDT_finance) &&
    //   this.getCookie(this.MDT_ecommerce) &&
    //   this.getCookie(this.MDT_custom_editor) &&
    //   this.getCookie(this.tenant) &&
    //   this.getCookie(this.app_domain)
    // );
  };

  getCookie = (name) => {
    let cookies = document.cookie.split('; ');
    for (let cookie_pair of cookies) {
      cookie_pair = cookie_pair.split('=');
      let key = cookie_pair[0];
      let value = cookie_pair[1];
      // console.log(cookie_pair, key, name)
      if (key === name) {
        return value;
      }
    }
    return null;
  };

  setLoggedInCookie = (client_id, domain) => {
    console.log('called');
    console.log(client_id, domain);
    document.cookie = `${this.logged_in}=${client_id}; Path=/; Domain=${domain}; max-age=3600`;
    console.log(document.cookie);
  };

  getLoggedInCookie = () => {
    return this.getCookie(this.logged_in);
  };

  setCookies = (cookies, domain) => {
    for (let cookie of cookies) {
      const key = cookie[0];
      const value = cookie[1];
      this.setCookie(key, value, domain);
      // console.log("cookie: ", cookie);
      // document.cookie = `${key}=${value}; Path=/; Domain=${domain}; max-age=3600`;
      // console.log(cookie[0]);
    }
    // console.log('This is the actual cookie after setting:', document.cookie);
  };

  setCookie = (key, value, domain) => {
    document.cookie = `${key}=${value}; Path=/; Domain=${domain}; max-age=2147483647`;
  };

  clearCookies = (domain) => {
    // console.log(domain);
    for (let cookie_const of this.cookie_consts) {
      let cookie = Object.entries(cookie_const)[0];
      let key = cookie[0];
      // console.log(key);
      document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${domain}`;
    }
    // console.log(document.cookie);
    window.location.href = '/';
  };
}

export default CookieManager;
