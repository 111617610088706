import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useContext } from 'react';
import auth0 from 'auth0-js';
import { AiOutlineLogout } from 'react-icons/ai';
import { LogoContext } from '../../Helper/Context';
import CookieManager from '../../helpers/CookieManager';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from 'react-router-dom';
import './profile.css';

const Profile = () => {
  const { logoContext } = useContext(LogoContext);
  console.log('LOGO CONTEXT: ', logoContext);
  let profile_pic_url = window.localStorage.getItem('profile_pic');
  console.log('PROFILE_PIC_URL: ', profile_pic_url);
  // const { getAccessTokenSilently } = useAuth0();
  // let [logout, setLogout] = useState(() => console.log('empty fun'));
  // let [user, setUser] = useState(null);
  // const token = async () => {
  //   let tokenReturn = await getAccessTokenSilently();
  //   return tokenReturn;
  // };
  let cookieManager = new CookieManager();
  let { domain } = cookieManager.getCookies();
  let logged_in = cookieManager.getLoggedInCookie();
  console.log(logged_in);
  const logout = async () => {
    let a0 = new auth0.WebAuth({
      domain,
      clientID: logged_in,
    });
    a0.logout({
      returnTo: window.location.origin,
    });
  };

  // console.log('TOKEN: ', token);
  // let token = getAccessTokenSilently();
  // console.log("TOKEN: ", token);
  // const eles = Array.from(new Array(7).keys());
  const eles = [
    'General',
    'Account',
    'Token',
    'Appearance',
    'Documentation',
    'Logout',
  ];

  console.log(eles);
  // const setClass = (ele) => {
  //   console.log("ELE: ", ele.target);
  //   let classList = ele.target.classList;
  //   console.log("CLASSLIST: ", classList);
  //   if(Array.from(classList).includes("selectedLink")) {
  //     classList.remove("selectedLink");
  //   } else {
  //     classList.add("selectedLink");
  //   }
  // }

  return (
    <div id="profile-container">
      <div id="top-navbar">
        <div class="top-nav-item"> Home </div>
        <div class="top-nav-item"> Account </div>
        <div class="top-nav-item" onClick={() => logout()}>
          {' '}
          <AiOutlineLogout id="logout-icon" />{' '}
        </div>
      </div>
      <div id="left-navbar">
        <div id="business-logo">
          <img src={profile_pic_url} />
        </div>
        <div id="left-nav-divs">
            <div> 
              <Link to={`/profile/General`} class="element-link" id="general-profile-link"  >
                  <div id="left-child-div"> General </div>
              </Link>
              <Link to={`/profile/Account`} class="element-link" id="general-profile-link">
                  <div id="left-child-div"> Account </div>
              </Link>
              <Link to={`/profile/Token`} class="element-link" id="general-profile-link">
                  <div id="left-child-div"> Token </div>
              </Link>
              <Link to={`#`} class="element-link" id="general-profile-link">
                  <div id="left-child-div"> Appearance </div>
              </Link>
              <Link to={`#`} class="element-link" id="general-profile-link">
                  <div id="left-child-div" > Documentation </div>
              </Link>
                  {/* <div id="left-child-div"  onClick={() => logout()}> Logout </div> */}

             
            </div>
        </div>
      </div>
      <div id="main-view">
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
