import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './StyledMenu';
import { Link } from 'react-router-dom';
import CookieManager from '../../helpers/CookieManager';
import './menu.css'
const Menu = ({ open }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;
  
  const cookieManager = new CookieManager();

  let domain = window.location.hostname;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} class="menu" id="hamburger-menu">
      {/* <a href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💁🏻‍♂️</span>
        About us
      </a>
      <a href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">💸</span>
        Pricing
        </a>
      <a href="/" tabIndex={tabIndex}>
        <span aria-hidden="true">📩</span>
        Contact
        </a>
      <a> Hello </a>  */}
      <div class="categoryContainer">
        <div class="category">
          <Link to="/finance">
            <div class="box" id="finance">
              {' '}
            </div>
          </Link>
        </div>

        <div class="category">
          <Link to="/ecommerce">
            <div class="box" id="ecommerce"></div>
          </Link>
        </div>

        <div class="category">
          <Link to="/editor">
            <div class="box" id="editor"></div>
          </Link>
        </div>

        <div class="category">
          <Link to="/flows">
            <div class="box" id="flows"></div>
          </Link>
        </div>

        <div class="category">
          <Link to="/okta">
            <div class="box" id="okta"></div>
          </Link>
        </div>

        {/* <div class="category">
          <div
            class="box"
            id="clear-cookies"
            onClick={() => cookieManager.clearCookies(domain)}
          >
            Clear Cookies
          </div>
        </div> */}
      </div>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
