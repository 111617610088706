import React from 'react';
import './generalForm.css';
function GeneralForm(props) {
    return (
        <div id="general-form-container">
           <div id='form-header'>General Information</div>
           <div id="general-name">
               <div id="input-group">
                    <label for="fname"> First Name  </label>
                    <input type="text" placeholder="Enter your first name" value="Derek"/>
                </div>
                <div id="input-group">
                    <label for="fname"> Last Name </label>
                    <input type="text" placeholder="Enter your last name" value="Barrett"/>
                </div>
            </div>
            <div id="general-demographic">
               <div id="input-group">
                    <label for="birthday"> Date of Birth</label>
                    <input type="date" value="1995-07-22" />
                </div>
                <div id="input-group">
                    <label for="gender"> Gender </label>
                    <select name="cars" id="gender-dropdown">
                        <option value="volvo">Male</option>
                        <option value="saab">Female</option>
                        <option value="mercedes">Prefer not to say</option>
                    </select>
                </div>
            </div>
            <div id='general-address'>
                <div id="input-group">
                    <label for="address"> Address </label>
                    <input type ="text" placeholder="Please enter your address" value="100 1st St 6th floor"/> 
                </div>
                <div id="input-group-city-state"> 
                    <div> 
                        <label for="address" > City </label>
                        <input type="text" placeholder="Please enter your city name" value="San Francisco"/>
                    </div>
                    <div>
                        <label for="address"> State </label>
                        <select name="cars" id="gender-dropdown">
                            <option value="volvo">California</option>
                            <option value="saab">Texas</option>
                            <option value="mercedes">Washington</option>
                            <option value="mercedes">New York</option>
                        </select>
                    </div>
                </div>
            </div>
            <div id='general-selection'>
                <div id="input-group">
                        <label for="gender"> Account Subscription </label>
                        <select name="cars" id="gender-dropdown">
                            <option value="volvo">Premium</option>
                            <option value="saab">Standard</option>
                            <option value="mercedes">No Subscription</option>
                        </select>
                </div>
            </div>
            
            <div id="save-form">
                <button> Edit </button>
                <button> Save </button>
            </div>
            </div>
    );
}

export default GeneralForm;