import React, { useState, useRef, useEffect } from 'react';
import './account.css';
import Card from './Card';
import CardForm from './CardForm';
const Second = () => {
    return <div id="account-profile-container">
        <div id="card-info">
            <div id="card-form"> 
                <CardForm />
            </div>
            <div id='card-pic'>
                <Card />
            </div>
        </div>
        
        </div>
}

export default Second;