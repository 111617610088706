import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  background: ${({ theme }) => theme.primaryLight};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-130%)'};
  height: 100vh;
  text-align: left;
  // padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  min-width: 195px;
  max-width: 200px;
  padding:10px;
  overflow: auto;
  background-color: #F8F8FF;
  // margin-top: 100px;
  // margin-left: 20px;
  background-color: rgba(255,255,255, 0.1);
  
  .nav::-webkit-scrollbar {
    display: none;
  }


  .categoryContainer {
    flex-grow: 1;
    justify-content: space-between; 
    background:none;
    margin-left: 10px;
    padding-right: 15px;
  }
  .categoryContainer::-webkit-scrollbar {
    display:none;
  }
  .category {
    // background-color: red;
    display: flex;
    flex-direction: row; 
    // flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  
  .box {
    height: 170px;
    flex-wrap: wrap;
    // justify-content: center;
    // align-content: center;
    flex-grow: 1;

    border: solid 1px black;
    width: 168px;
    background: white;
    border-radius: 20px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  }
  .box:hover {
    transition: transform .8s; /* Animation */
    transform: scale(1.15);
  }
  .categoryContainer {
    display: flex;
    flex-direction: column;

  }
  #ecommerce {
    background-image: url('./b2c.webp');
    background-size: cover;
    background-position: center;
  }
  #editor {
    background-image: url('./custom_editor.webp');
    background-size: cover;
  }
  #okta {
    background-image: url('./auth0-explorer.png');
    background-size: cover;
    background-position: center;
  }
  #flows {
    background-image: url('./auth0-flows.jpeg');
    background-size: contain;
    background-position: center;
  }
  #finance {
    background-image: url('./b2b.webp');
    background-size: cover;
    background-position: center;


  }
  #clear-cookies {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #clear-cookies:hover {
    cursor: grab;
  }
`;
