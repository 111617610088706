import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CookieManager from '../../helpers/CookieManager';
import config from '../../helpers/config';

import './tenant.css';

const Tenant = () => {
  let [tenant, setTenant] = useState(null);

  let { check_tenant_url } = config.mdt_vercel_backend;

  let domain_list = window.location.host.split('.');

  let cookieManager = new CookieManager();

  const grabTenant = async () => {
    // Is this a valid tenant?
    if (tenant) {
      let date = new Date().toUTCString();
      let data = {
        tenant,
        date
      };
      let options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      // Send this to our server for validation...whether it's been added to the demo platform
      let tenant_res = await fetch(check_tenant_url, options);
      // Valid res, config exists
      if (tenant_res.status === 200) {
        let tenant_json = await tenant_res.json();

        let app_domain = `${window.location.protocol}//${domain_list[0]}.${domain_list[1]}`;
        let base_app_domain = `${domain_list[0]}.${domain_list[1].split(':')[0]}`;
        
        let cookies = [
          ...Object.entries(tenant_json),
          ['tenant', tenant],
          ['app_domain', app_domain],
          ['base_app_domain', base_app_domain]
        ];

        cookieManager.setCookies(
          cookies,
          base_app_domain
        );

        window.location.href = app_domain;
      } else {
        alert(
          'Error: Please make sure the Modern Toolkit application is attached to your demo platform tenant.'
        );
      }
    }
  };
  return (
    <div id="tenant-container">
      <h1 id="tenant-title">
        Welcome to <br /> Modern Toolkit
      </h1>
      <div id="tenant-input-container">
        <input
          placeholder="Enter your tenant"
          value={tenant}
          onChange={(e) => setTenant(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              grabTenant();
            }
          }}
          id="tenant-input"
        ></input>
        <button id="tenant-enter-btn" onClick={() => grabTenant()}>
          Enter
        </button>
      </div>
    </div>
  );
};

export default Tenant;
