import React, { useState } from 'react';
import './token.css';
import JsonFormatter from 'react-json-formatter';
import auth0 from 'auth0-js';
import CookieManager from '../../helpers/CookieManager';

function Token(props) {

  let [idTokenPayload, setidTokenPayload] = useState(null);
  let [idToken, setIdToken] = useState(null);
  let cookieManager = new CookieManager();
  let { domain, app_domain } = cookieManager.getCookies();
  let logged_in = cookieManager.getLoggedInCookie();

  let a0 = new auth0.WebAuth({
    domain,
    clientID: logged_in,
  });

  a0.checkSession(
    {
      redirectUri: `${app_domain}/profile/general`,
      responseType: 'token id_token',
    },
    (err, res) => {
      if (err) {
        console.log('error', err);
      }
      if (!idTokenPayload) {
        setidTokenPayload(res.idTokenPayload);
      }

      if (!idToken) {
        setIdToken(res.idToken);
      }
    }
  );

  return (
    <div id="tokenContainer">
      <div id="tokenDisplay">
        <textarea id="tokenTextArea" value={idToken} readonly spellCheck="false" disabled/>
        <h3> Encoded ID Token </h3> 
      </div>
      <div id="tokenExplaination">
        <pre id="tokenExplanationPre">{JSON.stringify(idTokenPayload, undefined, 3)}</pre>
        <h3> Decoded ID Token </h3> 
      </div>
    </div>
  );
}

export default Token;
