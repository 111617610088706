import React, {useState} from 'react';
import './CardForm.css';
import { IoMdSend } from "react-icons/io";

import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
  

function CardForm(props) {
    const [basicModal, setBasicModal] = useState(false);
    const toggleShow = () => setBasicModal(!basicModal);

    return (
        <div id="card-form-container">
            <div id="card-form-group"> 
                <div id="card-form-input-group"> 
                    <label for='name'>Name </label>
                    <input type="text" value="Derek Barrett"/> 
                </div>
                <div id="card-form-input-group"> 
                    <label for='name'>Card Number </label>
                    <input type="text" value="**** ***** **** 5392" /> 
                </div>
                <div id="card-form-input-group-bottom"> 
                    <div id="card-form-input-group"> 
                        <label for='name'>Security Code </label>
                        <input id="bottom-input" type="text" value="***" /> 
                    </div>
                    <div id="card-form-input-group"> 
                        <label for='name'>Expiration </label>
                        <input id="bottom-input" type="text" value="09/25" /> 
                    </div>
                </div>
                <div id="edit-option"> 
                    <button onClick={() => toggleShow()}> Edit </button> 
                </div>
            </div>
            <div id="modal" style={ basicModal ? { display:'flex', zIndex: '100'} : {display : 'none'} } > 
                <div id="modal-header"> 
                    <div onClick={() => toggleShow()}><img src="https://cdn-icons-png.flaticon.com/512/6276/6276642.png" /></div>
                </div>
                <div id="modal-body">
                    <div id="modal-body-image"> 
                        <img src="https://assets.f-secure.com/f-secure/en/consumer/images/illustrations/articles/what-is-two-factor-authentication.png" />
                        <p> Attention, this feature require use to verify your identity. Please enter your phone number for SMS MFA.</p>
                        <div id="input-group-sms">
                            <input type="tel" id="phone-entry" placeholder="555-555-5555"/>
                            <IoMdSend id="sms-button"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default CardForm;