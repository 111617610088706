import React, { useState, useEffect, useContext } from 'react';
import './editor.css';
import axios from 'axios';
import ReactLoading from 'react-loading';
import { LogoContext } from '../../Helper/Context';

import auth0 from 'auth0-js';
import CookieManager from '../../helpers/CookieManager';
import config from '../../helpers/config';

function Editor(props) {
  let cookieManager = new CookieManager();
  let menu_element = document.querySelector('#styled-burger');
  let initial_search_container = document.querySelector('#search-box');
  let initial_search_button = document.querySelector('#searchBox');
  let [iframeSrc, setIframeSrc] = useState('');
  let [doneBranding, setDoneBranding] = useState(false);

  console.log('initial_searcH: ', initial_search_button);
  console.log('initial_search_container: ', initial_search_container);

  if (initial_search_button) {
    initial_search_container.addEventListener('click', function () {
      console.log('CLICKED');
      initial_search_button.style.animation = 'none';
    });
  }
  if (menu_element) {
    menu_element.style.animation = 'none';
    menu_element.style.opacity = '.07';
    menu_element.addEventListener('mouseover', () => {
      menu_element.style.opacity = '1';
    });
    menu_element.addEventListener('mouseout', () => {
      menu_element.style.opacity = '.07';
    });
  }
  if (!cookieManager.hasAllCookies()) {
    window.location.href = '/tenant';
  }

  let { domain, MDT_custom_editor, base_app_domain, app_domain, tenant } =
    cookieManager.getCookies();

  let { get_access_token_url, update_brand_url, get_brand_fetch_url } =
    config.mdt_vercel_backend;

  let { get_screenshot_url, get_brand_info_url } = config.mdt_heroku_backend;
  const { logoContext, setLogoContext, text, setText } =
    useContext(LogoContext);

  const [input, setInput] = useState('https://www.');
  const [image, setImage] = useState('./landing-background.jpeg');
  const [base64, setBase64] = useState();

  const [loading, setLoading] = useState(false);

  const Example = ({ type, color }) => (
    <div>
      <div class="center" style={{ backgroundImage: `url(${image})` }}>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div style={{ color: 'red', fontFamily: 'cursive', fontSize: '20px' }}>
          {' '}
          building...{' '}
        </div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </div>
  );
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      makeApiCall();
    }
  };
  const makeApiCall = () => {
    // console.log('IN USE EFFECT');
    setLoading(true);
    let urlObj = {
      url: input,
      domain,
    };
    // let k = `https://image.thum.io/get/width/1920/crop/1080/noanimate/${input}`;
    // let k = 'https://image.thum.io/get/width/1366/crop/768/https://www.google.com';
    // setImage(k)
    // setLoading(false);
    setBackgroundImage(urlObj);
    getBrand(urlObj);
  };
  const getBrand = async (urlObj) => {
    let obj = await axios.post(get_brand_fetch_url, urlObj).then((res) => {
      // console.log('Response: ', res.data);
      let obj = {};
      obj.color = res.data.colors;
      obj.logo = res.data.logos;
      obj.images = res.data.images;
      return obj;
    });
    obj.urlObj = urlObj;
    setBrand(obj);
  };
  const setBrand = async (obj) => {
    console.log(obj);
    // console.log('Object in Set Brand: ', obj.images[0].formats[0].src);
    let get_at_options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tenant }),
    };
    console.log('OBJ: ', obj);
    let get_at_res = await fetch(get_access_token_url, get_at_options);
    let { client_id, client_secret } = await get_at_res.json();
    let bgUrl = 'https://cdn.wallpapersafari.com/45/42/thcXDu.jpg';
    let logoUrl =
      'https://www.solidbackgrounds.com/images/950x350/950x350-white-solid-color-background.jpg';

    if (obj.images.length > 0 && obj.images[0].formats) {
      bgUrl = obj.images[0].formats[0].src;
    }
    if (obj.logo.length > 0 && obj.logo[0].formats) {
      logoUrl = obj.logo[0].formats[0].src;
      if (obj.logo.length > 1) {
        for (let i = 0; i < obj.logo.length; i++) {
          if (obj.logo[i].theme != 'light') {
            logoUrl = obj.logo[i].formats[0].src;
            break;
          }
        }
      }
    }
    let body = {
      domain,
      client_id,
      client_secret,
      brand: {
        logo_url: logoUrl,
        background_image_url: bgUrl,
      },
    };
    // console.log('CALLING SET LOGO CONTEXT: ', body.brand.logo_url);
    // setText('WHAT ABOUT NOW');
    // console.log(text);

    window.localStorage.setItem('profile_pic', body.brand.logo_url);
    await setLogoContext(body.brand.logo_url);

    // console.log('Logo Context in SET Brand!: ', logoContext);
    // console.log('BODY Set: ', body);
    let response = await axios.post(update_brand_url, body).then((res) => {
      // console.log('response: ', res.data);
      return res.data;
    });
    setDoneBranding(true);
    // console.log('RESPONSE: ', response);
  };
  const setBackgroundImage = async (urlObj) => {
    // var thum = require('thum.io');
    // let thumURL = thum.getThumURL({
    //   url: urlObj.url,
    //   crop: 2560,
    //   width: 1440,
    //   auth: {
    //     keyId: 67117,
    //     secret: 'ef3a8739e84d9f52adcdda7c6503cd12',
    //     type: 'raw'
    //   },
    //   viewportWidth: 2400
    // })
    // thumURL = `${thumURL}`
    // let thumURL = `https://image.thum.io/get/width/1920/crop/1080/viewportWidth/2400/${urlObj.url}`
    // setImage(thumURL)
    // setLoading(false);
    let response = await axios
      .get(
        `https://image.thum.io/get/width/1920/crop/1080/noanimate/viewportWidth/1920/wait/1/${urlObj.url}`,
        { responseType: 'blob' }
      )
      .then((response) => {
        // console.log("test", response.data, response);

        // const byteCharacters = atob(response.data);
        // const byteNumbers = new Array(byteCharacters.length);
        // for (let i = 0; i < byteCharacters.length; i++) {
        //   byteNumbers[i] = byteCharacters.charCodeAt(i);
        // }
        // const byteArray = new Uint8Array(byteNumbers);

        // let image = new Blob([byteArray], { type: 'image/png' });

        let imageUrl = URL.createObjectURL(response.data);

        console.log('imageURL: ', imageUrl);
        setImage(imageUrl);
        setLoading(false);
      })
      .catch((err) => {
        console.log('ERROR: ', err);
        setLoading(false);
      });
  };
  const openUniversalLogin = () => {
    let a0 = new auth0.WebAuth({
      domain,
      clientID: MDT_custom_editor,
    });
    a0.authorize({
      redirectUri: `${app_domain}/profile/general`,
      responseType: 'token id_token',
    });

    cookieManager.setLoggedInCookie(MDT_custom_editor, base_app_domain);
  };
  // logic that the value is null then only background image
  // console.log("Logo Context: ", logoContext);

  if (loading) {
    return (
      <div id="customContainer">
        <Example />
      </div>
    );
  } else if (image != './landing-background.jpeg') {
    return (
      <div id="customContainer" style={{ backgroundImage: `url(${image})` }}>
        {doneBranding && (
          <div id="loginButton" onClick={openUniversalLogin}>
            {' '}
          </div>
        )}
        {/* <div id='test'> 
          <img src={image} />
        </div>  */}
        <div id="searchContainer">
          <div class="search-box">
            <button class="btn-search" id="new-btn-search">
              <i class="fas fa-search"></i>
            </button>
            <input
              type="text"
              class="input-search"
              placeholder="Type to Search..."
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              value={input}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="customContainer" style={{ backgroundImage: `url(${image})` }}>
        {/* <iframe id="bg-img" src={iframeSrc}></iframe> */}
        <div id="searchContainer">
          <div class="search-box" id="search-box">
            <button class="btn-search" id="searchBox">
              <i class="fas fa-search"></i>
            </button>
            <input
              type="text"
              class="input-search"
              placeholder="Type to Search..."
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              value={input}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Editor;
