import React, { useContext, useEffect, useState } from 'react';
import './landing.css';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CookieManager from '../../helpers/CookieManager';
import config from '../../helpers/config';
import Modal from 'react-modal';
function Landing(props) {
  let cookieManager = new CookieManager();
  let { check_tenant_url } = config.mdt_vercel_backend;
  const [showModal, setShowModal] = useState(true);

  // Check if there's a subdomain
  let navigate = useNavigate();
  let domain_list = window.location.host.split('.');
  let app_domain = `${window.location.protocol}//${domain_list[1]}.${domain_list[2]}`;
 
  useEffect(() => {
    // No subdomain
    let menu_element  = document.querySelector("#styled-burger");
    if(menu_element) {
      menu_element.style.animation = 'pulsate-bck 0.5s ease-in-out infinite both';
      menu_element.style.opacity = 1;
    }
   
    https://docs.google.com/document/d/1n0cTaGUKZPQpgoC6OCpwdAKHmn2UIdPHOch4pj6CEsM/edit
    if (domain_list.length < 3 && !cookieManager.hasAllCookies()) {
      navigate('/tenant');
    } else if (!cookieManager.hasAllCookies()) {
      // Subdomain found, is it valid?
      let tenant = domain_list[0];
      // console.log('Subdomain found:', tenant);

      let data = {
        tenant,
      };
      let options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
      // Send this to our server for validation...whether it's been added to the demo platform
      let tenant_res = null;
      // console.log(options);
      fetch(check_tenant_url, options).then((res) => {
        if (res.status === 200) {
          res.json().then((tenant_json) => {
            // console.log(tenant_json);
            let base_app_domain = `${domain_list[1]}.${
              domain_list[2].split(':')[0]
            }`;
            let cookies = [
              ...Object.entries(tenant_json),
              ['tenant', tenant],
              ['app_domain', app_domain],
              ['base_app_domain', base_app_domain],
            ];

            cookieManager.setCookies(cookies, base_app_domain);

            window.location.href =
              domain_list.length > 2
                ? app_domain
                : `${window.location.protocol}//${domain_list[1]}`;
          });
        } else {
          window.location.href =
            domain_list.length > 2
              ? `${window.location.protocol}//${domain_list[1]}.${domain_list[2]}/tenant`
              : `${window.location.protocol}//${domain_list[1]}/tenant`;
        }
      });
    } else if (domain_list.length > 2 && cookieManager.hasAllCookies()) {
      window.location.href = app_domain;
    }
  }, []);
  const closeModal = () => {
    setShowModal(false);
  };

  const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      maxWidth: '700px',
      width: '90%',
      padding: '20px',
      background: '#fff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      color: 'black',
      fontFamily: 'Arial, sans-serif',
      textAlign: 'center',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
  return (
    <div className="scale-in-center">
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Hello Modal"
        style={customModalStyles} // Apply the custom styles
      >
        <h2>Please Install Modern Toolkit V2</h2>
        <p>
          You are currently using Modern Toolkit V1, which will be deprecated on <b> July 20th, 2023</b>.
        </p>
        <p>
          We have made extensive changes to the Modern Toolkit and have relaunched it as <b> Modern
          Toolkit V2 </b>. It is now available for installation!
        </p>
        {/* <button onClick={closeModal}>Close</button> */}
      </Modal>
      <div className="scale-up-center">
        <div className="container">
          <div id="imageDiv">
            <img
              id="logo"
              src="./Toolkit_Icon.png"
              width="500"
              height="300"
            />
          </div>

          <div id="toolTitle">
            {' '}
            <span id="toolTitleSpan"> Modern Toolkit </span>
          </div>
          <div id="attribution">
            {' '}
            Nithin Moorthy, Mark Vong, and Jeffrey Ciferno{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
