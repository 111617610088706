const vercel_base_url = 'https://mdt-backend.vercel.app/api';
// const vercel_base_url = 'http://localhost:3001/api';
const heroku_base_url = 'https://feature-matrix-server.herokuapp.com/api';
// const heroku_base_url = 'http://localhost:3001/api'

const config = {
  mdt_vercel_backend: {
    get_access_token_url: `${vercel_base_url}/getAccessToken`,
    get_brand_fetch_url: `${vercel_base_url}/getBrandFetch`,
    update_brand_url: `${vercel_base_url}/updateBrand`,
    check_tenant_url: `${vercel_base_url}/checkTenant`
  },
  mdt_heroku_backend: {
    get_screenshot_url: `${heroku_base_url}/screenshot-pikwy`,
    // get_screenshot_url: 'http://localhost:3001/api/screenshot-pikwy',
    get_brand_info_url: `${heroku_base_url}/getBrandInfo`
  },
};

export default config;
